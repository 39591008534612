import React from 'react';

interface Props {
  size? : number;
}

const LockOpen: React.FC<Props> = (props: Props) => {
  const { size = 20 } = props;
  return (
    <>
      <svg
        viewBox="0 0 15 20"
        width={size * 15 / 20}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 12.0415C8.09143 12.0415 8.57143 12.4901 8.57143 13.0428C8.57143 13.5955 8.09143 14.0441 7.5 14.0441C6.90857 14.0441 6.42857 13.5955 6.42857 13.0428C6.42857 12.4901 6.90857 12.0415 7.5 12.0415ZM7.5 16.0469C9.27214 16.0469 10.7143 14.6991 10.7143 13.043C10.7143 11.3868 9.27214 10.0391 7.5 10.0391C5.72786 10.0391 4.28571 11.3868 4.28571 13.043C4.28571 14.6991 5.72786 16.0469 7.5 16.0469ZM2.14286 17.9974H12.8571V7.98438H2.14286V17.9974ZM5.35714 5.98178V4.03124C5.35714 2.9268 6.31821 2.0026 7.5 2.0026C8.68179 2.0026 9.64286 2.97787 9.64286 3.97917H11.7857C11.7857 1.97657 9.86679 0 7.5 0C5.13321 0 3.21429 1.81937 3.21429 4.03124V5.98178H0V20H15V5.98178H5.35714Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default LockOpen;
