import React from 'react';
import './Header.css';
import Link from 'components/Link/Link';
import { useSelector } from 'react-redux';
import {
  isAuthenticatedSelector,
  authHomeUrlSelector,
  authCandidateHasCvSelector,
  authCandidateSuperSearchAvailableSelector,
  authUserGroupSelector,
} from 'store/ducks/auth/selectors';
import ContainerWrapper from 'containers/common/Layout/Container';
import Grid from 'components/Grid/Grid';
import UserNav from 'components/UserNav/UserNav';
import Nav from 'components/Nav/Nav';
import NavItem from 'components/NavItem/NavItem';
import LockOpen from 'components/Icons/LockOpen/LockOpen';
import Button from 'components/v2/Button/Button';
import { useIntl } from 'react-intl';
import NotificationBadge from 'components/NotificationBadge/NotificationBadge';
import { LANDING_URL, LOCALE, OUTSTAFF } from 'utils/base';
import { Logo } from './Logo';

const Header: React.FC = () => {
  const intl = useIntl();
  const isAuthorized = useSelector(isAuthenticatedSelector);
  const authHomeUrl = useSelector(authHomeUrlSelector);
  const candidateHasCv = useSelector(authCandidateHasCvSelector);
  const candidateSuperSearchAvailable = useSelector(authCandidateSuperSearchAvailableSelector);
  const currentUserGroup = useSelector(authUserGroupSelector);

  const unauthorizedMenuItems = [
    {
      link: 'https://itvit.ru/employers',
      title: intl.formatMessage({ id: 'app.header.common.forcompanies' }),
    },
    {
      link: 'https://itvit.ru/',
      title: intl.formatMessage({ id: 'app.header.common.fordevelopers' }),
    },
    {
      link: 'https://itvit.ru/about',
      title: intl.formatMessage({ id: 'app.header.common.about' }),
    },
    {
      link: 'https://itvit.ru/mission',
      title: intl.formatMessage({ id: 'app.header.common.mission' }),
    },
  ];

  return (
    <div className="geecko-v2-header">
      <ContainerWrapper className="geecko-v2-header__inner">
        <Grid container fullHeight noWrap>
          <Grid item md={2} lg={2} hide={['xs', 'sm']}>
            <div className="geecko-v2-header__logo">
              <Link to={isAuthorized ? authHomeUrl : LANDING_URL || ''}>
                <Logo />
              </Link>
            </div>
          </Grid>
          <Grid item>
            {isAuthorized ? (
              <Nav>
                {currentUserGroup === 'company' && (
                  <NavItem section="vacancies" underline>
                    <Link to="/vacancies">{intl.formatMessage({ id: 'app.vacancy.page.vacancy' })}</Link>
                  </NavItem>
                )}
                {currentUserGroup === 'candidate' && candidateHasCv && (
                  <>
                    <NavItem section="personal-offer" underline>
                      <Link to="/personal-offer">
                        {intl.formatMessage({ id: 'app.header.candidate.personaloffers' })}
                      </Link>
                    </NavItem>
                    {candidateSuperSearchAvailable && (
                      <NavItem section="search" underline>
                        <Link to="/search">{intl.formatMessage({ id: 'app.header.candidate.supersearch' })}</Link>
                      </NavItem>
                    )}
                  </>
                )}
              </Nav>
            ) : (
              <>
                {LOCALE === 'ru' && (
                  <Nav>
                    {unauthorizedMenuItems.slice(0, 2).map((item) => (
                      <NavItem key={item.link}>
                        <a href={item.link}>{item.title}</a>
                      </NavItem>
                    ))}
                    <span style={{ color: '#C4C4C4' }} className="geecko-util__hidden-md">
                      |
                    </span>
                    {unauthorizedMenuItems.slice(2).map((item) => (
                      <NavItem key={item.link}>
                        <a href={item.link}>{item.title}</a>
                      </NavItem>
                    ))}
                  </Nav>
                )}
              </>
            )}
          </Grid>

          <Grid item sm={4} xs={4} hide={['lg', 'md', 'xl']}>
            <div className="geecko-v2-header__logo">
              <Link to={isAuthorized ? authHomeUrl : LANDING_URL || ''}>
                <Logo />
              </Link>
            </div>
          </Grid>

          <Grid item toRight>
            <div className="geecko-v2-header__right">
              {isAuthorized ? (
                <UserNav />
              ) : (
                <Nav icon={<LockOpen size={18} />} title={intl.formatMessage({ id: 'app.auth.entry' })}>
                  <Link to="/auth">
                    <Button variant="ghost" color="secondary" size="sm">
                      {intl.formatMessage({ id: 'app.auth.title' })}
                    </Button>
                  </Link>
                  <Link to={OUTSTAFF ? '/register/company' : '/register'}>
                    <Button size="sm" variant="outline">
                      {intl.formatMessage({ id: 'app.auth.registeration' })}
                    </Button>
                  </Link>
                </Nav>
              )}
            </div>
          </Grid>
        </Grid>
      </ContainerWrapper>
    </div>
  );
};

export default Header;
