import React, { useEffect, useState } from 'react';
import { hideChatra, initChatra } from 'untyped';
import Footer from 'components/Footer/Footer';
import NotifyStack from 'components/NotifyStack/NotifyStack';
import { useSelector } from 'react-redux';
import VerifyEmail from 'components/VerifyEmail/VerifyEmail';
import Header from 'components/v2/Header/Header';
import {
  authSelector, authUserGroupSelector,
} from '../../store/ducks/auth/selectors';
import { User } from '../../models/User';
import '../Page/Page.css';

interface Props {
  hideAppBar?: boolean;
  withFooter?: 'candidate' | 'company';
  backgroundColor?: string;
}

const AppLayout: React.FC<Props> = ({ hideAppBar, backgroundColor, withFooter, children }) => {
  const auth = useSelector(authSelector);
  const userGroup = useSelector(authUserGroupSelector);

  const [chatraLoaded, setChatraLoaded] = useState(false);

  const loadChatra = () => {
    if (auth.user) {
      setChatraLoaded(true);
      const currentUser = auth.user as User;
      const currentCompany = auth.company ? auth.company : undefined;
      const chatraVars = {
        name: currentUser.fullName,
        email: currentUser.email,
        companyName: currentCompany ? currentCompany.name : null,
      };

      const chatraUserGroup = userGroup === 'company' ? 'rAjJMXM8WnPs4TjMb' : 'tet262FDtQ4akSfoa';

      initChatra(chatraVars, currentUser ? currentUser.chatraId : undefined, { groupId: chatraUserGroup });
    }
  };

  const unLoadChatra = () => {
    setChatraLoaded(false);
    hideChatra();
  };

  useEffect(() => {
    if (auth.user && !chatraLoaded) {
      loadChatra();
    } else if (!auth.user && chatraLoaded) {
      unLoadChatra();
    }
  }, [auth, chatraLoaded]);

  return (
    <>
      <div className="geecko-app" style={backgroundColor ? { backgroundColor } : undefined}>
        {auth.user && !auth.user.emailVerifiedAt && <VerifyEmail />}
        {!hideAppBar && (
          <Header />
        )}
        <div className="geecko-app__content">{children}</div>
        {withFooter && (
          <div style={{ marginTop: 64 }}>
            <Footer withFooter={withFooter} />
          </div>
        )}
        <NotifyStack />
        {process.env.CI_COMMIT_SHORT_SHA && (
          <div className="geecko-app__dev-info">
            <a
              title={`branch: ${process.env.CI_COMMIT_REF_NAME}`}
              href={`https://gitlab.com/iwuteam/platform-front/commit/${process.env.CI_COMMIT_SHORT_SHA}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              {process.env.CI_COMMIT_SHORT_SHA}
              <span className="geecko-app__dev-info-branch">{process.env.CI_COMMIT_REF_NAME}</span>
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export default AppLayout;
