import React, { ErrorInfo } from 'react';
import * as Sentry from '@sentry/browser';
import Empty from 'components/Empty/Empty';
import EmptyError from 'components/Svg/Empty/EmptyError';
import { injectIntl, WrappedComponentProps } from 'react-intl';

interface State {
  hasError: boolean;
}

class ErrorBoundaryWithIntl extends React.Component<any & WrappedComponentProps, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  public render() {
    const { intl } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Empty
          icon={<EmptyError />}
          message={intl.formatMessage({ id: 'app.error.server.title' })}
          description={
            <>
              <p>{intl.formatMessage({ id: 'app.error.server.textfirst' })}</p>
              <p>{intl.formatMessage({ id: 'app.error.server.textsecond' })}</p>
            </>
          }
        />
      );
    }

    const { children } = this.props;

    return children;
  }
}

const ErrorBoundary = injectIntl(ErrorBoundaryWithIntl);

export default ErrorBoundary;
