import React from 'react';

interface Props {
  size? : number;
}

const Coffee: React.FC<Props> = (props: Props) => {
  const { size = 14 } = props;
  return (
    <>
      <svg
        viewBox="0 0 57 57"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.42243 15.833C8.19505 15.833 9.6337 14.4144 9.6337 12.6664V3.1666C9.6337 1.41864 8.19505 0 6.42243 0C4.64981 0 3.21117 1.41864 3.21117 3.1666V12.6664C3.21117 14.4144 4.64981 15.833 6.42243 15.833ZM44.9579 28.4992H38.5353V37.999H44.9579C52.4498 37.999 52.4498 28.4992 44.9579 28.4992ZM32.1127 28.4992H9.63387C7.86125 28.4992 6.4226 29.9178 6.4226 31.6658V37.4543C6.4226 44.0694 11.3262 49.9782 18.0056 50.6052C25.6677 51.3272 32.1127 45.4057 32.1127 37.999V28.4992ZM44.9577 44.3325H37.4145C34.5275 52.3725 26.3356 57.9362 16.9715 56.8691C7.1547 55.7481 0 47.0811 0 37.3343V28.4995C0 25.0004 2.87408 22.1663 6.42253 22.1663H38.5352H44.9577C61.0141 22.1663 61.0141 44.3325 44.9577 44.3325ZM32.1125 15.833C33.8851 15.833 35.3237 14.4144 35.3237 12.6664V3.1666C35.3237 1.41864 33.8851 0 32.1125 0C30.3399 0 28.9012 1.41864 28.9012 3.1666V12.6664C28.9012 14.4144 30.3399 15.833 32.1125 15.833ZM16.0564 12.6664V3.1666C16.0564 1.41864 17.495 0 19.2676 0C21.0403 0 22.4789 1.41864 22.4789 3.1666V12.6664C22.4789 14.4144 21.0403 15.833 19.2676 15.833C17.495 15.833 16.0564 14.4144 16.0564 12.6664Z"
          fill="currentColor"
        />
      </svg>

    </>
  );
};

export default Coffee;
