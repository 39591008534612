import React, { FC } from 'react';

interface Props {
  width?: number;
}

export const Logo: FC<Props> = ({ width = 108 }) => {
  const height = width * 29 / 108;
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 429 183" >
        <path d="M201.282 144L180.646 102.61H202.918L213.751 126.372L237.349 72.0392H258.847L225.36 144H201.282Z" fill="#30C952"/>
        <path d="M213.796 63.5763C209.019 63.5763 205.675 62.6208 203.764 60.7099C201.948 58.7034 201.041 56.3147 201.041 53.5439V49.3876C201.041 46.5212 201.948 44.1326 203.764 42.2216C205.675 40.3107 209.019 39.3552 213.796 39.3552C218.574 39.3552 221.87 40.3107 223.685 42.2216C225.596 44.1326 226.552 46.5212 226.552 49.3876V53.5439C226.552 56.3147 225.596 58.7034 223.685 60.7099C221.87 62.6208 218.574 63.5763 213.796 63.5763Z" fill="#30C952"/>
        <path d="M92.4952 126.522H75.7224V88.5213H92.4952V72.0395H54.5111V126.522H39.2014V144H92.4952V126.522Z" fill="#1A1B35"/>
        <path d="M146.18 144C138.537 144 132.852 141.994 129.126 137.981C125.399 133.968 123.536 127.86 123.536 121.649V88.5213H103.471V72.0395H116.513C119.666 72.0395 121.912 71.4184 123.249 70.1763C124.587 68.9342 125.256 66.6411 125.256 63.297V42.2219H144.747V72.0395H173.554V88.5213H144.747V126.522H173.554V144H146.18Z" fill="#1A1B35"/>
        <path d="M361.827 144C354.184 144 348.499 141.993 344.772 137.98C341.046 133.968 339.183 127.86 339.183 121.649V88.521H319.118V72.0392H332.16C335.313 72.0392 337.559 71.4182 338.896 70.1761C340.234 68.934 340.903 66.6409 340.903 63.2967V42.2216H360.394V72.0392H389.201V88.521H360.394V126.522H389.201V144H361.827Z" fill="#1A1B35"/>
        <path d="M75.1492 60.7101C73.2382 62.6211 69.8941 63.5765 65.1168 63.5765C60.3394 63.5765 57.0431 62.6211 55.2277 60.7101C53.3168 58.7037 52.3613 56.315 52.3613 53.5441V49.3879C52.3613 46.5215 53.3168 44.1328 55.2277 42.2219C57.0431 40.3109 60.3394 39.3555 65.1168 39.3555C69.8941 39.3555 73.2382 40.3109 75.1492 42.2219C76.9646 44.1328 77.8722 46.5215 77.8722 49.3879V53.5441C77.8722 56.315 76.9646 58.7037 75.1492 60.7101Z" fill="#1A1B35"/>
        <path d="M269.809 126.522H286.582V88.521H269.809V72.0392H307.793V126.522H323.103V144H269.809V126.522Z" fill="#1A1B35"/>
        <path d="M287.155 60.7099C289.066 62.6208 292.41 63.5763 297.187 63.5763C301.965 63.5763 305.261 62.6208 307.076 60.7099C308.987 58.7034 309.943 56.3147 309.943 53.5439V49.3876C309.943 46.5212 308.987 44.1326 307.076 42.2216C305.261 40.3107 301.965 39.3552 297.187 39.3552C292.41 39.3552 289.066 40.3107 287.155 42.2216C285.339 44.1326 284.432 46.5212 284.432 49.3876V53.5439C284.432 56.3147 285.339 58.7034 287.155 60.7099Z" fill="#1A1B35"/>
    </svg>
  );
};
