import React, { FC } from 'react';
import './Container.css';
import classNames from 'classnames';

interface Props {
  className?: string;
  noPadding?: boolean;
}

/**
 * Оборачиваем в этот компонент все, что должно иметь максимальную ширину на очень широких экранах
 */
export const Container: FC<Props> = ({ className, noPadding, children }) => {
  return (
    <div
      className={classNames('geecko-layout-container', className, noPadding ? 'geecko-layout-container--no-padding' : undefined)}
    >
      {children}
    </div>
  );
}