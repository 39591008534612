import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import './Footer.css';
import { useSelector } from 'react-redux';
import Text from 'components/v2/Text/Text';
import Link from 'components/Link/Link';
import Button from 'components/v2/Button/Button';
import Container from 'containers/common/Layout/Container';
import { authUserSelector } from '../../store/ducks/auth/selectors';
// eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-ignore
// @ts-ignore
import { Router } from '../../../server/routes';
import LinkPrivacyPolicy from '../Link/LinkPrivacyPolicy';

interface Props {
  inviteCode?: string;
  withFooter?: 'candidate' | 'company';
}

const Footer: FC<Props> = ({ inviteCode, withFooter }) => {
  const intl = useIntl();
  const currentUser = useSelector(authUserSelector);
  const registerType = withFooter || 'candidate';
  const handleSignup = () => {
    const inviteSuffix = inviteCode ? `?invite=${inviteCode}` : '';
    Router.push(`/register/${registerType}?${inviteSuffix}`);
  };

  const handleSignIn = () => {
    Router.push('/auth');
  };

  const candidateLinks = [{ title: intl.formatMessage({ id: 'app.jobsearch' }), link: 'https://geecko.ru/' }];

  const companyLinks = [
    { title: 'Geecko Marketplace', link: 'https://geecko.ru/employers/marketplace' },
    { title: 'Geecko Skills', link: 'https://geecko.ru/employers/skills' },
  ];

  const geeckoLinks = [
    { title: intl.formatMessage({ id: 'app.header.common.about' }), link: 'https://geecko.ru/about' },
    { title: intl.formatMessage({ id: 'app.header.common.mission' }), link: 'https://geecko.ru/mission' },
  ];

  return (
    <Container>
      <footer className="geecko-footer">
        <div className="geecko-footer__first-line">
          <div className="geecko-footer__block-container">
            <div className="geecko-footer__block geecko-footer__block-candidate">
              <div className="geecko-footer__block-title">
                <Text variant="h3">{intl.formatMessage({ id: 'app.header.common.fordevelopers' })}</Text>
              </div>
              {candidateLinks.map((item) => (
                <div className="geecko-util__mb-1" key={item.title}>
                  <Link to={item.link} target="_blank">
                    <Text variant="md" color="#4D4E57">
                      {item.title}
                    </Text>
                  </Link>
                </div>
              ))}
            </div>
            <div className="geecko-footer__block geecko-footer__block-company">
              <div className="geecko-footer__block-title">
                <Text variant="h3">{intl.formatMessage({ id: 'app.header.common.foremployers' })}</Text>
              </div>
              {companyLinks.map((item) => (
                <div className="geecko-util__mb-1" key={item.title}>
                  <Link to={item.link} target="_blank">
                    <Text variant="md" color="#4D4E57">
                      {item.title}
                    </Text>
                  </Link>
                </div>
              ))}
            </div>
            <div className="geecko-footer__block geecko-footer__block-pools">
              <div className="geecko-footer__block-title">
                <Text variant="h3">Geecko</Text>
              </div>
              {geeckoLinks.map((item) => (
                <div className="geecko-util__mb-1" key={item.title}>
                  <Link to={item.link} target="_blank">
                    <Text variant="md" color="#4D4E57">
                      {item.title}
                    </Text>
                  </Link>
                </div>
              ))}
            </div>
            <div className="geecko-footer__block geecko-footer__contacts">
              <div className="geecko-util__mb-1">
                <Link to="https://www.facebook.com/geecko.ru/" target="_blank">
                  <Text variant="md" color="#4D4E57">
                    Facebook
                  </Text>
                </Link>
              </div>
              <div className="geecko-util__mb-1">
                <Text variant="md" color="#4D4E57">
                  hello@geecko.ru
                </Text>
              </div>
              <div className="geecko-util__mb-1">
                <Text variant="md" color="#4D4E57">
                  +7&nbsp;499&nbsp;281&nbsp;5230
                </Text>
              </div>
            </div>
          </div>
          {!currentUser && (
            <div className="geecko-footer__auth">
              <Button onClick={handleSignup}>{intl.formatMessage({ id: 'app.signup' })}</Button>
              <Button variant="outline" onClick={handleSignIn}>
                {intl.formatMessage({ id: 'app.auth.button.login' })}
              </Button>
            </div>
          )}
        </div>
        <div className="geecko-footer__second-line">
          <div>
            <Text variant="sm" color="#858A9C">
              {intl.formatMessage({ id: 'app.geecko.requisites' })}
            </Text>
          </div>
          <div className="geecko-footer__docs">
            <Link to="/terms-of-use" target="_blank">
              <Text variant="sm" color="#858A9C">
                {intl.formatMessage({ id: 'app.docs.useragreement' })}
              </Text>
            </Link>
            <LinkPrivacyPolicy title={intl.formatMessage({ id: 'app.privacypolicy.pagetitle' })} />
          </div>
        </div>
      </footer>
    </Container>
  );
};

export default Footer;
