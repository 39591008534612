import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import startsWith from 'lodash/startsWith';
import './NavItem.css';
import classNames from 'classnames';
import Text from 'components/v2/Text/Text';

interface Props {
  section?: string;
  underline?: boolean;
}

const Nav: React.FC<Props> = ({ section, underline, children }) => {
  const router = useRouter();
  const isCurrent = useMemo(() => section && startsWith(router.asPath, `/${section}`), [section, router]);
  return (
    <li className={classNames(
      'geecko-nav-item',
      isCurrent ? 'geecko-nav-item--current' : undefined,
      underline ? 'geecko-nav-item--underline' : undefined,
    )}
    >
      <Text variant="md" bold>{children}</Text>
    </li>
  );
};

export default Nav;
