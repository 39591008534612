/* eslint-disable no-param-reassign */
import React from 'react';
import App from 'next/app';
import { IntlProvider } from 'react-intl';
import messagesRu from 'translations/ru.json';
import messagesEn from 'translations/en.json';
import AppLayout from 'components/App/AppLayout';
import ErrorBoundary from 'components/ErrorBoundary';
import 'components/App/custom-properties.css';
import 'components/App/fonts.css';
import 'components/App/App.css';
import { Provider } from 'react-redux';
import { NextPageContext } from 'next';
import nookies, { setCookie } from 'nookies';
import { Store } from 'redux';
import Router from 'next/router';
import NProgress from 'nprogress';
import { ParsedUrlQuery } from 'querystring';
import withReduxSaga from 'next-redux-saga';
import withRedux from 'next-redux-wrapper';
import qs from 'qs';
import startsWith from 'lodash/startsWith';
import ym, { YMInitializer } from 'react-yandex-metrika';
import { decodeResumeStatus } from 'models/Candidate/UserProfile';
import addWeeks from 'date-fns/addWeeks';
import { AppState, initializeStore } from 'store';
import { CandidateCvImport } from 'store/ducks/auth/state';
import {
  authUserGroupSelector,
  authCandidateHasCvSelector,
  authCandidateCvFormStepSelector,
  authTokenSelector,
} from 'store/ducks/auth/selectors';
import {
  fetchCurrentUserAction,
  setAuthTokenAction,
  setAuthUserAction,
  setAuthManagerAction,
  setAuthCompanyAction,
  setAuthCandidateAction,
  setCandidateCvImportStatusAction,
  setCandidateResumeStatusAction,
} from 'store/ducks/auth/actionCreators';
import camelcaseKeys from 'camelcase-keys';
import { decodeAuthCompany } from 'models/Company/AuthCompany';
import { LOCALE } from 'utils/base';
import { addHiddenElement, changeVacanciesSort, specialTariffsViewed } from '../src/store/ducks/settings';
import { VacanciesSort } from '../src/models/Vacancy';
import { decodeAuthCandidate } from '../src/models/Candidate';
import RenderError from '../src/components/Error/RenderError';
import { RedirectError, ServerError } from '../src/server-error';
import { decodeUser, UserGroup } from '../src/models/User';
import { deleteCookie, logDebug, nextRedirect, setHttpCookie } from '../src/models/helpers';
import { HIDEABLE_COOKIE_PREFIX } from '../src/components/HideableElement/HideableElement';
import sentry from '../src/modules/sentry';
import { ServerApi } from '../src/modules/api/server-api';
import ApiProvider from '../src/modules/api/ApiProvider';
import { decodeManager } from '../src/models/Manager';

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

type Dictionary = { [index: string]: any };
const messages: Dictionary = {
  ru: messagesRu,
  en: messagesEn,
};

export const TRACKED_QUERY_PARAMS = [
  'invite_id',
  'code',
  'utm_source',
  'utm_campaign',
  'utm_medium',
  'utm_term',
  'utm_contents',
];
const language = LOCALE;

const { captureException } = sentry();

// Интерфейс с дополнительными свойствами, которые можно прокинуть в компонент страницы с сервера
export interface CommonInitialProps {
  error?: {
    statusCode: number;
    message?: string;
  };
  hideAppBar?: boolean;
  backgroundColor?: string;
  withFooter?: 'candidate' | 'company';
  query: ParsedUrlQuery;
  api: ServerApi;
}

export interface NextContextStore {
  store: Store<AppState>;
  api: ServerApi;
}

export type NextPage<P = {}, IP = P> = {
  (props: P & CommonInitialProps): JSX.Element | null;
  defaultProps?: Partial<P>;
  displayName?: string;

  // Опциональное Статическое свойство. Если передано, то только указанные группы имеют доступ к текущей странице
  accessGroups?: UserGroup | UserGroup[];

  /**
   * Used for initial page load data population. Data returned from `getInitialProps`
   * is serialized when server rendered.
   * Make sure to return plain `Object` without using `Date`, `Map`, `Set`.
   * @param ctx Context of `page`
   */
  getInitialProps?(ctx: NextPageContext & NextContextStore): Promise<IP & Partial<CommonInitialProps>>;
};

class GeeckoApp extends App<NextContextStore> {
  constructor(props: any) {
    super(props);

    this.state = {
      hasError: false,
      errorEventId: undefined,
    };
  }

  // Only uncomment this method if you have blocking data requirements for
  // every single page in your application. This disables the ability to
  // perform automatic static optimization, causing every page in your app to
  // be server-side rendered.
  //
  // В этом методе можно пробросить свойства в контекст, которые пригодятся при серверном рендеринге на всех страницах
  // Как например статус авторизации
  static async getInitialProps(appContext: any) {
    // Store приложения
    const { store } = appContext.ctx;
    const serverApi = new ServerApi(store);
    const authPath = `/auth?${qs.stringify({ return_url: appContext.ctx.asPath })}`;

    let cookies = nookies.get(appContext.ctx);

    // Проверяем куку о том, что спец предложения просмотрены
    if (cookies.geecko_tariffs_viewed && cookies.geecko_tariffs_viewed === '1') {
      store.dispatch(specialTariffsViewed());
    }
    // Настройка сортировки вакансий
    if (cookies.geecko_vacancies_sort && ['new', 'created_at'].indexOf(cookies.geecko_vacancies_sort) >= 0) {
      store.dispatch(changeVacanciesSort(cookies.geecko_vacancies_sort as VacanciesSort));
    }

    // Трекинговые куки
    TRACKED_QUERY_PARAMS.forEach((paramName) => {
      const { query } = appContext.ctx;
      if (typeof query[paramName] === 'string' && query[paramName]) {
        setCookie(appContext.ctx, `tr_${paramName}`, query[paramName], {
          path: '/',
          domain: process.env.REACT_APP_COOKIE_DOMAIN || undefined,
          expires: addWeeks(new Date(), 1),
        });
      }
    });

    // Куки скрываемых элементов
    Object.keys(cookies).forEach((cookieName) => {
      if (!startsWith(cookieName, HIDEABLE_COOKIE_PREFIX)) {
        return;
      }

      if (cookies[cookieName] !== '1') {
        return;
      }

      const settingKeyName = cookieName.replace(HIDEABLE_COOKIE_PREFIX, '');
      store.dispatch(addHiddenElement(settingKeyName));
    });

    if (cookies.geecko_token && !cookies.geecko_atoken) {
      const token = cookies.geecko_token;
      deleteCookie('geecko_token', appContext.ctx, false);
      setHttpCookie('geecko_atoken', token, appContext.ctx);
    }

    cookies = nookies.get(appContext.ctx);
    let authToken: string | undefined;

    // Проверяем куку авторизации или берем токен из url /* get-параметра _geecko_token */
    if (cookies.geecko_atoken || appContext.ctx.query.authtoken) {
      const mainAuthToken = cookies.geecko_atoken || appContext.ctx.query.authtoken;
      authToken = mainAuthToken;
      store.dispatch(setAuthTokenAction(mainAuthToken));
    } else {
      const token = authTokenSelector(store.getState());
      if (token) {
        authToken = token;
      }
    }

    let accessGroups: UserGroup[] = [];

    if (appContext.Component.accessGroups) {
      accessGroups = Array.isArray(appContext.Component.accessGroups)
        ? appContext.Component.accessGroups
        : [appContext.Component.accessGroups];
    }

    // Если есть кука, проверяем, кто мы
    if (authToken) {
      if (!appContext.ctx.req) {
        store.dispatch(fetchCurrentUserAction.request());
      } else {
        try {
          const meResponse = await serverApi.get('/user/me');
          if (meResponse && meResponse.data) {
            if (meResponse.data.user) {
              const authorizedUser = decodeUser(meResponse.data.user);
              store.dispatch(setAuthUserAction(authorizedUser));
            }
            if (meResponse.data.manager) {
              store.dispatch(setAuthManagerAction(decodeManager(meResponse.data.manager)));
            }
            if (meResponse.data.company) {
              store.dispatch(setAuthCompanyAction(decodeAuthCompany(meResponse.data.company)));
            }
            if (meResponse.data.candidate) {
              const candidate = decodeAuthCandidate(meResponse.data.candidate);
              store.dispatch(setAuthCandidateAction(candidate));

              if (meResponse.data.candidate.parser_status) {
                try {
                  const cvImportStatus = camelcaseKeys(meResponse.data.candidate.parser_status) as CandidateCvImport;
                  store.dispatch(setCandidateCvImportStatusAction(cvImportStatus));
                } catch (e) {
                  logDebug('Произошла ошибка при запросе CV кандидата', e);
                }
              } else {
                store.dispatch(setCandidateCvImportStatusAction(undefined));
              }
            }
            if (meResponse.data.resume_status) {
              const resumeStatus = decodeResumeStatus(meResponse.data.resume_status);
              store.dispatch(setCandidateResumeStatusAction(resumeStatus));
            }
          }
        } catch (e) {
          if (e.response && e.response.status && e.response.status === 401) {
            logDebug('Сервер ответил кодом 401, удаляем токен авторизации', e.response);
            deleteCookie('geecko_token', appContext.ctx, false);
            deleteCookie('geecko_atoken', appContext.ctx);
            if (accessGroups && accessGroups.length > 0 && accessGroups.indexOf('public') < 0) {
              if (appContext.ctx.res) {
                appContext.ctx.res.writeHead(302, {
                  Location: authPath,
                });
                appContext.ctx.res.end();
              }
            }
          }
        }
      }
    }

    // Проверяем уровень доступа, если он указан
    if (accessGroups.length > 0) {
      const currentUserGroup = authUserGroupSelector(store.getState());
      if (accessGroups.indexOf(currentUserGroup) < 0) {
        let redirectUrl = '/';
        if (accessGroups.indexOf('public') < 0) {
          redirectUrl = authPath;
        } else if (currentUserGroup === 'company') {
          redirectUrl = '/vacancies';
        } else if (currentUserGroup === 'candidate') {
          // Проверяем что у кандидата заполнено CV, если нет, отправляем на форму заполнения
          const candidateHasCv = authCandidateHasCvSelector(store.getState());
          const candidateCvFormStep = authCandidateCvFormStepSelector(store.getState());
          redirectUrl = candidateHasCv
            ? '/personal-offer'
            : `/my/cv/edit${candidateCvFormStep ? `/${candidateCvFormStep}` : ''}`;
        }

        nextRedirect(redirectUrl, appContext.ctx.res, 302);
        return {
          pageProps: undefined,
        };
      }
    }

    try {
      const initialPageProps = appContext.Component.getInitialProps
        ? await appContext.Component.getInitialProps({
            ...appContext.ctx,
            api: serverApi,
          })
        : {};

      const pageProps = {
        ...initialPageProps,
        query: appContext.ctx.query,
      };

      return { pageProps };
    } catch (e) {
      if (e instanceof RedirectError) {
        nextRedirect(e.redirectUrl, appContext.ctx.res, e.statusCode);
        return {
          pageProps: undefined,
        };
      }
      if (e instanceof ServerError) {
        if (appContext.ctx.res) {
          appContext.ctx.res.statusCode = e.statusCode;
        }
        return {
          pageProps: {
            isError: true,
            title: e.message,
          },
        };
      }
      // Обрабатываем ошибку от Axios
      if (e.response) {
        logDebug('Произошла ошибка в axios', e.response);
        if (e.response.status) {
          switch (e.response.status) {
            case 401:
              nextRedirect(authPath, appContext.ctx.res, 302);
              return { pageProps: {} };
            case 404:
              if (appContext.ctx.res) {
                appContext.ctx.res.statusCode = 404;
              }
              return {
                pageProps: {
                  isError: true,
                  title: '404 - Страница не найдена',
                },
              };
          }
        }
      }

      // Любые другие ошибки, которые не обработались ранее
      if (appContext.ctx.res) {
        appContext.ctx.res.statusCode = 502;
      }

      logDebug('Произошла другая ошибка', e);

      const errorEventId = captureException(e, appContext.ctx);

      return {
        pageProps: {
          isError: true,
          errorEventId,
        },
      };
    }
  }

  hitYM = (url?: string) => {
    if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
      const hitPath = url ? `${window.location.origin}${url}` : window.location.href;
      ym('hit', hitPath);
    }
  };

  public componentDidMount() {
    /**
     * При клике по кнопке назад браузер по умолчанию сразу возвращает позицию скролла, которая была на предыдущей странице
     * Однако в нашем случае страница еще не готова для отображения, поэтому до загрузки скролл будет изменен
     * Этот хак взят отсюда https://github.com/zeit/next.js/issues/3303#issuecomment-535452263
     */
    window.history.scrollRestoration = 'manual';
    const cachedScrollPositions: any = [];
    let shouldScrollRestore: any = false;

    Router.events.on('routeChangeStart', () => {
      cachedScrollPositions.push([window.scrollX, window.scrollY]);
    });

    Router.events.on('routeChangeComplete', () => {
      if (shouldScrollRestore) {
        const { x, y } = shouldScrollRestore;
        window.scrollTo(x, y);
        shouldScrollRestore = false;
      }
    });

    Router.events.on('routeChangeComplete', this.hitYM);
    this.hitYM();

    Router.beforePopState(() => {
      if (
        typeof cachedScrollPositions !== 'undefined' &&
        Array.isArray(cachedScrollPositions) &&
        cachedScrollPositions.length > 0
      ) {
        const [x, y] = cachedScrollPositions.pop();
        shouldScrollRestore = { x, y };
      }

      return true;
    });
  }

  public componentWillUnmount() {
    Router.events.off('routeChangeComplete', this.hitYM);
  }

  render() {
    const { Component, pageProps, store } = this.props;
    if (pageProps.isError) {
      return (
        <Provider store={store}>
          <ApiProvider>
            <IntlProvider locale={language} messages={messages[language]}>
              {process.env.NODE_ENV === 'production' ? (
                <YMInitializer
                  accounts={[55684792]}
                  options={{
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true,
                  }}
                />
              ) : null}
              <AppLayout>
                <RenderError title={pageProps.title} />
              </AppLayout>
            </IntlProvider>
          </ApiProvider>
        </Provider>
      );
    }
    return (
      <Provider store={store}>
        <ApiProvider>
          <IntlProvider locale={language} messages={messages[language]}>
            {process.env.NODE_ENV === 'production' ? (
              <YMInitializer
                accounts={[55684792]}
                options={{
                  clickmap: true,
                  trackLinks: true,
                  accurateTrackBounce: true,
                  webvisor: true,
                }}
              />
            ) : null}
            <ErrorBoundary>
              <AppLayout
                hideAppBar={pageProps && pageProps.hideAppBar}
                withFooter={pageProps && pageProps.withFooter}
                backgroundColor={pageProps && pageProps.backgroundColor}
              >
                {pageProps.isError && <div>Ошибка</div>}
                {!pageProps.isError && <Component {...pageProps} />}
              </AppLayout>
            </ErrorBoundary>
          </IntlProvider>
        </ApiProvider>
      </Provider>
    );
  }
}

export default withRedux(initializeStore)(withReduxSaga(GeeckoApp));
