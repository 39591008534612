import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import './Nav.css';
import NavSwitch from 'components/Icons/NavSwitch/NavSwitch';
import { useOnClickOutside } from 'hooks/use-on-click-outside';

interface Props {
  isOpen?: boolean; // TODO expandable menu on mobile
  icon?: JSX.Element;
  title?: string;
}

const Nav: React.FC<Props> = ({ children, icon, title }) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const toggleNav = () => {
    setOpen(prev => !prev);
  };
  const node = useRef(null);
  const handleClose = () => {
    setOpen(false);
  };
  useOnClickOutside(node, handleClose);
  return (
    <>
      <div className="geecko-nav-switch" onClick={toggleNav} role="button" tabIndex={0}>
        {icon || <NavSwitch />}
      </div>
      <ul ref={node} className={`geecko-nav ${open ? 'geecko-nav--open' : ''}`} onClick={toggleNav} role="presentation">
        <div className="geecko-nav-switch geecko-nav-switch--closing">
          {icon || <NavSwitch />}
          <span>{title || intl.formatMessage({ id: 'app.menu' })}</span>
        </div>
        {children}
      </ul>
    </>
  );
};

export default Nav;

interface NavContextType {
  closeNav?: () => void;
}

export const NavContext = React.createContext<NavContextType>({
  closeNav: undefined,
});
