/* eslint-disable */

import { LOCALE } from "utils/base";

interface ChatraOptions {
  groupId?: string;
}

// @ts-ignore
export const initChatra = (initVars: any, chatraId: any = undefined, options: ChatraOptions = {}) => {
  if (chatraId && typeof window !== "undefined") {
    // @ts-ignore
    window.ChatraSetup = {
      clientId: chatraId,
      language: LOCALE,
    }

    if (options.groupId) {
      // @ts-ignore
      window.ChatraGroupID = options.groupId;
    }
  }
  // @ts-ignore
  if (typeof Chatra !== "undefined") {
    // @ts-ignore
    Chatra('setIntegrationData', initVars);
    // @ts-ignore
    Chatra('show');
    return;
  }
  (function (d, w, c) {
    // @ts-ignore
    w.ChatraID = 'tErhC3R9HAYsJG4no';
    const s = d.createElement('script');
    // @ts-ignore
    w[c] = w[c] || function () { (w[c].q = w[c].q || []).push(arguments); };
    s.async = true;
    s.src = 'https://call.chatra.io/chatra.js';
    if (d.head) d.head.appendChild(s);
    // @ts-ignore
    Chatra('setIntegrationData', initVars);
  }(document, window, 'Chatra'));
};

export const hideChatra = () => {
  // @ts-ignore
  Chatra('setIntegrationData', {});
  // @ts-ignore
  Chatra('hide');
};

export const openChatra = () => {
  // @ts-ignore
  Chatra('openChat', true);
}
