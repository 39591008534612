import React from 'react';
import './UserNavItem.css';

interface Props {
  muted?: boolean;
}

const UserNavItem: React.FC<Props> = ({ muted, children }) => (
  <div className={[
    'geecko-user-nav-item',
    muted ? 'geecko-user-nav-item--muted' : '',
  ].join(' ')}
  >
    {children}
  </div>
);

export default UserNavItem;
