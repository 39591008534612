import * as Sentry from '@sentry/node';
import { NodeOptions } from '@sentry/node/dist/backend';
import { NextPageContext } from 'next';
import { ErrorInfo } from 'react';

type NextError = (Error & {
  statusCode?: number;
});

export const isSentryEnabled = !!process.env.REACT_APP_SENTRY_DSN;

interface ErrorContextProps {
  errorInfo?: ErrorInfo;
}

export default (release = process.env.SENTRY_RELEASE) => {
  const sentryOptions: NodeOptions = {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    debug: process.env.NODE_ENV !== 'production',
    release,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    // Игнорируем репорт о следущих ошибках
    ignoreErrors: [
      // Эту ошибку мы сами вызываем для того чтобы предотвратить навигцию роутера некста
      'Abort page navigation, please ignore this error',
      // Эту ошибку можно спокойно игнорировать
      'ResizeObserver loop limit exceeded',
      // Уточнить, но похоже тоже не влияет на функционал
      'ResizeObserver loop completed with undelivered notifications',
      // Ошибка от расширения Evernote Web Cliper
      'There is no clipping info for given tab',
    ],
  };

  // When we're developing locally
  if (process.env.NODE_ENV !== 'production') {
    // Don't actually send the errors to Sentry
    sentryOptions.beforeSend = () => null;
  }

  if (isSentryEnabled) {
    Sentry.init(sentryOptions);
  }

  return {
    Sentry,
    captureException: (err: NextError, ctx?: Partial<NextPageContext & ErrorContextProps>) => {
      Sentry.configureScope(scope => {
        if (err.message) {
          // De-duplication currently doesn't work correctly for SSR / browser errors
          // so we force deduplication by error message if it is present
          scope.setFingerprint([err.message]);
        }

        if (err.statusCode) {
          scope.setExtra('statusCode', err.statusCode);
        }

        if (ctx) {
          const {
            req, res, query, pathname, errorInfo,
          } = ctx;

          if (res && res.statusCode) {
            scope.setExtra('statusCode', res.statusCode);
          }

          if (typeof window !== 'undefined') {
            scope.setTag('ssr', 'false');
            scope.setExtra('query', query);
            scope.setExtra('pathname', pathname);
          } else {
            scope.setTag('ssr', 'true');
            if (req) {
              scope.setExtra('url', req.url);
              scope.setExtra('method', req.method);
              scope.setExtra('headers', req.headers);
            }
          }

          if (errorInfo) {
            const newErrorInfo = errorInfo as any;
            Object.keys(newErrorInfo).forEach(key => scope.setExtra(key, newErrorInfo[key] as string));
          }
        }
      });

      return Sentry.captureException(err);
    },
  };
};
