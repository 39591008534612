import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './NotifyStack.css';
import { Notify as NotifyType, NotifyConfig } from 'models/Notify';
import Notify from 'components/Notify/Notify';
import { useSelector } from 'react-redux';
import { notificationsItemsSelector } from '../../store/ducks/notify';

const NotifyStack: React.FC = () => {
  const notifications = useSelector(notificationsItemsSelector);
  const [isMounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  if (!isMounted) {
    return null;
  }
  const pageRoot = document.body;
  return ReactDOM.createPortal(
    <div className="geecko-notify-stack">
      {notifications.map((notification: NotifyType, index) => (
        <Notify
          key={notification.id}
          notify={notification}
          cancelNow={notifications.length - index > NotifyConfig.maxNotify}
        />
      ))}
    </div>,
    pageRoot,
  );
};

export default NotifyStack;
