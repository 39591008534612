import React, { ReactNode, ReactNodeArray } from 'react';
import Coffee from 'components/Icons/Coffee/Coffee';
import './Empty.css';

interface Props {
  message: string;
  description?: string | ReactNode | ReactNodeArray;
  icon?: ReactNode;
  variant?: 'muted' | undefined;
}

const Empty: React.FC<Props> = ({
  message, icon, variant, description,
}) => (
  <div className={['geecko-empty', variant ? `geecko-empty--${variant}` : ''].join(' ')}>
    <div className="geecko-empty__icon">{icon || <Coffee size={57} />}</div>
    <div className="geecko-empty__message">{message}</div>
    {typeof description !== 'undefined' && (
      <div className="geecko-empty__description">
        {description}
      </div>
    )}
  </div>
);

export default Empty;
