import React, { FC } from 'react';

const EmptyError: FC = () => (
  <svg width="274" height="253" viewBox="0 0 274 253" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.2" d="M132.297 193.181C100.736 165.905 60.1224 174.361 40.9351 166.291C12.1197 154.147 -1.85683 109.963 61.4875 74.8235C124.832 39.684 146.011 35.0743 184.496 60.3183C215.29 80.5163 222.519 121.595 218.509 146.032C212.235 184.262 163.848 220.445 132.297 193.181Z" fill="#A7BDE5" />
    <path d="M256.001 94.9999L256.001 175.41L273.011 175.41L273.011 94.9999L256.001 94.9999Z" fill="#223762" />
    <path d="M252.861 95.0099L252.861 175.42L269.871 175.42L269.871 95.0099L252.861 95.0099Z" fill="#A7BDE5" />
    <path d="M261.402 171.181C264.384 171.181 266.802 168.763 266.802 165.781C266.802 162.799 264.384 160.381 261.402 160.381C258.42 160.381 256.002 162.799 256.002 165.781C256.002 168.763 258.42 171.181 261.402 171.181Z" fill="#617FAA" />
    <path d="M258.613 165.781C258.613 164.579 259.016 163.412 259.758 162.466C260.499 161.52 261.536 160.849 262.703 160.561C261.903 160.349 261.066 160.324 260.255 160.487C259.444 160.65 258.682 160.997 258.026 161.502C257.371 162.006 256.84 162.654 256.475 163.396C256.11 164.138 255.92 164.954 255.92 165.781C255.92 166.608 256.11 167.424 256.475 168.166C256.84 168.908 257.371 169.556 258.026 170.06C258.682 170.565 259.444 170.912 260.255 171.075C261.066 171.238 261.903 171.213 262.703 171.001C261.536 170.713 260.499 170.042 259.758 169.096C259.016 168.15 258.613 166.983 258.613 165.781V165.781Z" fill="#223762" />
    <path d="M261.402 125.379C264.384 125.379 266.802 122.961 266.802 119.979C266.802 116.996 264.384 114.579 261.402 114.579C258.42 114.579 256.002 116.996 256.002 119.979C256.002 122.961 258.42 125.379 261.402 125.379Z" fill="#617FAA" />
    <path d="M258.613 119.979C258.613 118.777 259.016 117.61 259.758 116.664C260.499 115.717 261.536 115.047 262.703 114.759C261.903 114.547 261.066 114.522 260.255 114.685C259.444 114.848 258.682 115.195 258.026 115.699C257.371 116.204 256.84 116.852 256.475 117.594C256.11 118.336 255.92 119.152 255.92 119.979C255.92 120.806 256.11 121.622 256.475 122.363C256.84 123.105 257.371 123.754 258.026 124.258C258.682 124.762 259.444 125.109 260.255 125.273C261.066 125.436 261.903 125.41 262.703 125.199C261.536 124.91 260.499 124.24 259.758 123.294C259.016 122.348 258.613 121.181 258.613 119.979V119.979Z" fill="#223762" />
    <path d="M261.422 141.161C264.405 141.161 266.822 138.743 266.822 135.761C266.822 132.779 264.405 130.361 261.422 130.361C258.44 130.361 256.022 132.779 256.022 135.761C256.022 138.743 258.44 141.161 261.422 141.161Z" fill="#617FAA" />
    <path d="M258.592 135.761C258.593 134.559 258.996 133.392 259.737 132.446C260.478 131.5 261.515 130.829 262.682 130.541C261.883 130.329 261.045 130.304 260.234 130.467C259.424 130.63 258.661 130.977 258.006 131.482C257.351 131.986 256.82 132.634 256.455 133.376C256.089 134.118 255.899 134.934 255.899 135.761C255.899 136.588 256.089 137.404 256.455 138.146C256.82 138.888 257.351 139.536 258.006 140.04C258.661 140.545 259.424 140.892 260.234 141.055C261.045 141.218 261.883 141.193 262.682 140.981C261.515 140.693 260.478 140.022 259.737 139.076C258.996 138.13 258.593 136.963 258.592 135.761V135.761Z" fill="#223762" />
    <path d="M261.407 111.276C264.389 111.276 266.807 108.858 266.807 105.876C266.807 102.894 264.389 100.476 261.407 100.476C258.425 100.476 256.007 102.894 256.007 105.876C256.007 108.858 258.425 111.276 261.407 111.276Z" fill="#617FAA" />
    <path d="M258.608 105.876C258.608 104.674 259.011 103.507 259.753 102.561C260.494 101.615 261.531 100.944 262.698 100.656C261.898 100.444 261.061 100.419 260.25 100.582C259.439 100.745 258.677 101.092 258.022 101.597C257.366 102.101 256.835 102.749 256.47 103.491C256.105 104.233 255.915 105.049 255.915 105.876C255.915 106.703 256.105 107.519 256.47 108.261C256.835 109.003 257.366 109.651 258.022 110.155C258.677 110.66 259.439 111.007 260.25 111.17C261.061 111.333 261.898 111.308 262.698 111.096C261.531 110.808 260.494 110.137 259.753 109.191C259.011 108.245 258.608 107.078 258.608 105.876V105.876Z" fill="#223762" />
    <path d="M102.001 80L155.044 80C160.907 80 166.712 80.9182 172.129 82.7023C177.546 84.4863 182.468 87.1012 186.613 90.3977C190.759 93.6942 194.048 97.6077 196.291 101.915C198.535 106.222 199.69 110.838 199.69 115.5C199.69 120.162 200.845 124.778 203.088 129.085C205.332 133.392 208.621 137.306 212.766 140.602C216.912 143.899 221.834 146.514 227.251 148.298C232.667 150.082 238.473 151 244.336 151L263.001 151" stroke="#7A87A1" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M266.761 151.02C266.761 148.038 264.343 145.62 261.361 145.62C258.379 145.62 255.961 148.038 255.961 151.02C255.961 154.002 258.379 156.42 261.361 156.42C264.343 156.42 266.761 154.002 266.761 151.02Z" fill="#223762" />
    <path d="M203.931 71H51.751V181.02H203.931V71Z" fill="#223762" />
    <path d="M200.331 71H50.001V181.02H200.331V71Z" fill="url(#paint0_linear)" />
    <path d="M194.461 77.4099H55.8906V174.63H194.461V77.4099Z" fill="url(#paint1_linear)" />
    <path d="M58.6106 77.4099H55.8906V174.63H58.6106V77.4099Z" fill="#223762" />
    <path d="M58.6006 157.94L94.9706 133.84L137.481 142.85L181.871 94.1699L194.461 108.04V174.62H58.6006V157.94Z" fill="#D6E3FA" />
    <path d="M80.191 114.13C85.1284 114.13 89.131 109.116 89.131 102.93C89.131 96.7444 85.1284 91.73 80.191 91.73C75.2536 91.73 71.251 96.7444 71.251 102.93C71.251 109.116 75.2536 114.13 80.191 114.13Z" fill="white" />
    <path d="M79.5205 144.08L91.7505 149.36L102.15 142.24L123.581 139.9L94.9705 133.84L79.5205 144.08Z" fill="white" />
    <path d="M167.591 109.82L177.401 115.72L183.751 111.39L190.511 114.12L194.761 112.77L194.461 108.04L181.871 94.1699L167.591 109.82Z" fill="white" />
    <path d="M58.6006 164.91C69.655 157.723 82.9672 154.854 96.0006 156.85C118.091 160.13 120.001 171.47 140.731 166.7C161.461 161.93 169.971 138.35 179.461 140.14C188.951 141.93 194.761 152.09 194.761 152.09L194.451 174.63H58.6006V164.91Z" fill="white" />
    <path d="M58.6006 166.7C58.6006 166.7 73.9106 157.45 96.0006 160.13C118.091 162.81 120.001 172.06 140.731 168.13C161.461 164.2 169.971 145.04 179.461 146.5C188.951 147.96 194.761 156.24 194.761 156.24L194.451 174.63H58.6006V166.7Z" fill="#3769A5" />
    <defs>
      <linearGradient id="paint0_linear" x1="159.711" y1="78.88" x2="109.071" y2="148" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4C93F4" />
        <stop offset="1" stopColor="#0B63DA" />
      </linearGradient>
      <linearGradient id="paint1_linear" x1="35723.8" y1="3162.2" x2="31250.7" y2="9537.84" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4C93F4" />
        <stop offset="1" stopColor="#0B63DA" />
      </linearGradient>
    </defs>
  </svg>
);

export default EmptyError;
