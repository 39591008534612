import React from 'react';

interface Props {
  size? : number;
}

const Browser: React.FC<Props> = (props: Props) => {
  const { size = 16 } = props;
  return (
    <>
      <svg
        viewBox="0 0 77 77"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.1 15.4H69.3V7.70004H23.1V15.4ZM7.7 69.3001H69.3V23.1001H7.7V69.3001ZM7.7 15.4H15.4V7.70004H7.7V15.4ZM0 77H77V0H0V77Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default Browser;
