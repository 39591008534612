import React from 'react';

interface Props {
  size? : number;
}

const NavSwitch: React.FC<Props> = (props: Props) => {
  const { size = 14 } = props;
  return (
    <>
      <svg
        viewBox="0 0 17 14"
        width={size * 17 / 14}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
      >
        <rect width="17" height="2" transform="matrix(-1 0 0 1 17 0)" fill="currentColor" />
        <rect width="10" height="2" transform="matrix(-1 0 0 1 10 6)" fill="currentColor" />
        <rect width="7" height="2" transform="matrix(-1 0 0 1 7 12)" fill="currentColor" />
      </svg>
    </>
  );
};

export default NavSwitch;
