import React, { useRef, useEffect, useState, KeyboardEvent, MouseEvent as ReactMouseEvent } from 'react';
import { useIntl } from 'react-intl';
import './UserNav.css';
import Avatar from 'components/Avatar/Avatar';
import UserNavItem from 'components/UserNavItem/UserNavItem';
import Link from 'components/Link/Link';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Router from 'next/router';
import NProgress from 'nprogress';
import SettingsIcon from 'components/Icons/SettingsIcon';
import UserRoundedIcon from 'components/Icons/UserRoundedIcon';
import Rouble from 'components/Icons/Rouble/Rouble';
import EditPaperRounded from 'components/Icons/EditPaperRounded';
import { OUTSTAFF } from 'utils/base';
import {
  authCompanySelector,
  authMenuRoleTitleSelector,
  authUserGroupSelector,
  authUserSelector,
  authCandidateSelector,
} from '../../store/ducks/auth/selectors';
import { isServer } from '../../models/helpers';

const UserNav: React.FC = () => {
  const intl = useIntl();
  const node = useRef<HTMLDivElement>(null);
  const user = useSelector(authUserSelector);
  const company = useSelector(authCompanySelector);
  const candidate = useSelector(authCandidateSelector);
  const [open, setOpen] = useState(false);
  const handleLogOut = (event: ReactMouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    // eslint-disable-next-line no-restricted-globals,no-alert
    if (!confirm(intl.formatMessage({ id: 'app.exit.confirm' }))) {
      return;
    }
    window.location.href = '/auth/logout';
    NProgress.start();
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const closeNav = () => {
    setOpen(false);
  };

  useEffect(() => {
    Router.events.on('routeChangeStart', closeNav);

    return () => {
      Router.events.off('routeChangeStart', closeNav);
    };
  }, []);

  const handleKeyboardToggle = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Escape':
      case 'Tab':
        event.preventDefault();
        setOpen((prevOpen) => !prevOpen);
        break;
    }
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (node && node.current && node.current.contains(event.target as Node)) {
      return;
    }
    setOpen(false);
  };

  const userMenuRoleTitle = useSelector(authMenuRoleTitleSelector);
  const userGroup = useSelector(authUserGroupSelector);

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const renderUserBlock = (full = true) => {
    let fullName = user ? user.fullName : '';
    if (candidate && candidate.fullName) fullName = candidate.fullName;
    return (
      <div
        className="geecko-user-nav__header-menu"
        onClick={handleToggle}
        onKeyDown={handleKeyboardToggle}
        role="button"
        tabIndex={0}
      >
        {full && (
          <div className="geecko-user-nav__name-wrapper">
            <div className="geecko-user-nav__full-name">{fullName}</div>
            {userMenuRoleTitle && <div className="geecko-user-nav__position">{userMenuRoleTitle}</div>}
          </div>
        )}
        <div className="geecko-user-nav__photo">
          <Avatar size={30} image={user ? user.avatar : ''} fullName={fullName} />
        </div>
      </div>
    );
  };

  return (
    <div ref={node} className="geecko-user-nav">
      {renderUserBlock()}
      {!isServer && (
        <div
          className={classNames({
            'geecko-user-nav__links': true,
            'geecko-user-nav__links--opened': open,
          })}
        >
          {renderUserBlock()}
          {userGroup === 'company' && !OUTSTAFF && (
            <>
              {company && company.balance && (
                <div className="geecko-user-nav__links__section geecko-user-nav__links__section--balance">
                  <Link to="/my/company/billing">
                    <UserNavItem>
                      <Rouble size={14} />
                      {company.balance.amount}
                    </UserNavItem>
                  </Link>
                </div>
              )}
            </>
          )}
          <div className="geecko-user-nav__links__section">
            {userGroup === 'company' && (
              <>
                {/* TODO: add new menu item after merge add-company-page branch*/}
                {/* <Link to={`/company-profile/${companyId}`} onClick={handleToggle}>
                  <UserNavItem>
                    <____Icon size={16} />
                    Моя компания
                  </UserNavItem>
                </Link> */}
                <Link to="/my/company/edit" onClick={handleToggle}>
                  <UserNavItem>
                    <EditPaperRounded size={16} />
                    {intl.formatMessage({ id: 'app.company.menu.settings' })}
                  </UserNavItem>
                </Link>
                <Link to="/my/profile/edit">
                  <UserNavItem>
                    <SettingsIcon size={16} />
                    {intl.formatMessage({ id: 'app.account.menu.settings' })}
                  </UserNavItem>
                </Link>
              </>
            )}
            {userGroup === 'candidate' && (
              <>
                {candidate && !!candidate.surveyedAt ? (
                  <Link to={`/resume/${candidate.hash}`}>
                    <UserNavItem>
                      <UserRoundedIcon size={16} />
                      {intl.formatMessage({ id: 'app.caddidate.resume' })}
                    </UserNavItem>
                  </Link>
                ) : null}
                <Link to="/my/cv/edit" onClick={handleToggle}>
                  <UserNavItem>
                    <EditPaperRounded size={16} />
                    {intl.formatMessage({ id: 'app.caddidate.resume.edit' })}
                  </UserNavItem>
                </Link>
                <Link to="/my/profile/notifications">
                  <UserNavItem>
                    <SettingsIcon size={16} />
                    {intl.formatMessage({ id: 'app.account.menu.settings' })}
                  </UserNavItem>
                </Link>
              </>
            )}
            <Link to="/auth/logout" onClick={handleLogOut}>
              <UserNavItem muted>
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.625 13.125H3.125C2.79348 13.125 2.47554 12.9933 2.24112 12.7589C2.0067 12.5245 1.875 12.2065 1.875 11.875V3.125C1.875 2.79348 2.0067 2.47554 2.24112 2.24112C2.47554 2.0067 2.79348 1.875 3.125 1.875H5.625"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 10.625L13.125 7.5L10 4.375"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M13.125 7.5H5.625" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                {intl.formatMessage({ id: 'app.usernav.menu.logout' })}
              </UserNavItem>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserNav;
