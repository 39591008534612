import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import PageNext from '../Page/PageNext';
import Empty from '../Empty/Empty';
import Browser from '../Icons/Browser/Browser';

interface Props {
  title?: string;
}

const RenderError: FC<Props> = ({ title }) => {
  const intl = useIntl();
  const resultTitle = title || intl.formatMessage({ id: 'app.error' });
  return(
  <PageNext title={title}>
    <Empty
      icon={<Browser size={64} />}
      message={resultTitle}
    />
  </PageNext>
)};

export default RenderError;
