import React from 'react';

interface Props {
  size? : number;
}

const Rouble: React.FC<Props> = (props: Props) => {
  const { size = 15 } = props;
  return (
    <>
      <svg
        viewBox="0 0 16 16"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
      >
        <path
          d="M2.52595 14.4218V11.7018H0.78595V10.4018H2.52595V8.62185H0.78595V7.10185H2.52595V0.141846H6.28595C8.03262 0.141846 9.32595 0.495179 10.166 1.20185C11.0193 1.90851 11.446 2.93518 11.446 4.28185C11.446 5.64185 10.986 6.70851 10.066 7.48185C9.14595 8.24185 7.79262 8.62185 6.00595 8.62185H4.32595V10.4018H7.62595V11.7018H4.32595V14.4218H2.52595ZM4.32595 7.10185H5.74595C6.95928 7.10185 7.89928 6.90185 8.56595 6.50185C9.24595 6.10185 9.58595 5.38185 9.58595 4.34185C9.58595 3.43518 9.30595 2.76185 8.74595 2.32185C8.18595 1.88185 7.31262 1.66185 6.12595 1.66185H4.32595V7.10185Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default Rouble;
