import React, { useEffect, useCallback, useState } from 'react';
import './Notify.css';
import { Notify as NotifyType, NotifyConfig } from 'models/Notify';
import { useDispatch } from 'react-redux';
import { deleteNotification } from '../../store/ducks/notify';

interface Props {
  notify: NotifyType;
  cancelNow?: boolean;
}

const NotifyStack: React.FC<Props> = (props: Props) => {
  const {
    notify,
    cancelNow,
    ...other
  } = props;
  const {
    id, content, type, persist, cancelable,
  } = notify;
  const dispatch = useDispatch();
  const [canceling, setCanceling] = useState(false);
  const handleCancel = useCallback(() => {
    setCanceling(true);
    if (id) {
      setTimeout(() => dispatch(deleteNotification(id)), 250);
    }
  }, [dispatch, id]);
  useEffect(() => {
    if (cancelNow) {
      handleCancel();
      return undefined;
    }
    const timer = setTimeout(handleCancel, NotifyConfig.autoHideDuration);
    return () => clearTimeout(timer);
  }, [cancelNow, handleCancel, id, persist]);

  return (
    <div
      className={[
        'geecko-notify geecko-notify--fadeIn',
        type ? `geecko-notify--${type}` : '',
        canceling ? 'geecko-notify--fadeOut' : '',
      ].join(' ')}
      {...other}
    >
      <div className="geecko-notify__content">
        {content}
      </div>
      {cancelable && (
        <div className="geecko-notify__cancel" onClick={handleCancel} onKeyDown={handleCancel} role="button" tabIndex={0} />
      )}
    </div>
  );
};

export default NotifyStack;
