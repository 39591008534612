import { useEffect } from 'react';

export const useOnClickOutside = (ref: React.RefObject<any>, handler: (event: MouseEvent | TouchEvent) => void) => {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent) => {
        if (!ref || (!ref.current || ref.current.contains(event.target))) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [ref, handler],
  );
};
