import React, { FC } from 'react';
import { LOCALE } from 'utils/base';
import Link from './Link';

interface Props {
  title: string;
}

const LinkPrivacyPolicy: FC<Props> = ({ title }) => (
  <Link to={LOCALE === 'ru' ? '/privacy-policy' : '/docs/privacy-policy_en.pdf'} target="_blank">
    {title}
  </Link>
);
export default LinkPrivacyPolicy;
