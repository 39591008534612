import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import './VerifyEmail.css';
import MailIcon from 'components/Icons/MailIcon';
import { useApiInstance } from 'api';
import { showNotifyNew } from 'models/Notify';
import { useSelector, useDispatch } from 'react-redux';
import { authUserSelector } from 'store/ducks/auth/selectors';

const VerifyEmail: React.FC<{}> = () => {
  const intl = useIntl();
  const api = useApiInstance();
  const dispatch = useDispatch();
  const [actions, setActions] = useState<{ sent: boolean; loading: boolean }>({ sent: false, loading: false });
  const user = useSelector(authUserSelector);

  const handleResend = async () => {
    setActions({ sent: false, loading: true });
    let result;
    try {
      result = await api.post('/candidate/resend-verification-email', { email: user ? user.email : '' });
    } catch (error) {
      result = error;
    }
    if (result && result.data) {
      setActions((prevState) => ({ ...prevState, sent: true }));
      showNotifyNew(dispatch, {
        content: intl.formatMessage({ id: 'app.verifyemail.linksend' }),
        type: 'success',
      });
    } else {
      showNotifyNew(dispatch, {
        content: `Server error:
            ${result.statusCode ? result.statusCode : ''}
            ${result.message ? result.message : ''}`,
        type: 'error',
      });
    }
    setActions((prevState) => ({ ...prevState, loading: false }));
  };

  return (
    <div className="geecko-verify-email geecko--print-hide">
      <div className="geecko-verify-email__icon">
        <MailIcon />
      </div>
      <div className="geecko-verify-email__content">
        {intl.formatMessage({ id: 'app.verifyemail.confirmlinksend' })}
        {!actions.sent && (
          <button className="geecko-verify-email__action" onClick={handleResend} disabled={actions.loading}>
            {intl.formatMessage({ id: 'app.verifyemail.sendagain' })}
          </button>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
